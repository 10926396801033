import React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import ExternalLink from "../components/externalLink/externalLink"

import HeroCTA2 from "../components/hero/hero-cta2"
import RightCta from "../components/cta/right-bordered/cta"
import LeftCta from "../components/cta/left-bordered/cta"
// import Savings from "../components/savings/savings";
import withDoctor from "../images/getting-started/withDoctor2.jpg"
import gradientThree from "../images/clinical-trial-results/gradientThree.png"
import blinkPharma from    "../images/getting-started/blink_pharma.png"


import desktopHero from "../images/getting-started/desktopHero.png"
import mobileHero from "../images/getting-started/mobileHero.png"
import gradientOne from "../images/getting-started/gradientOne.png"
// import gradientTwo from "../images/getting-started/gradientTwo.png"
import ctaBackground from "../images/getting-started/ctaBackground.svg"

import BlinkRx from "../images/getting-started/blinkRx.png"
// import glassHeart from "../images/getting-started/4.0_550x380_Glass_Heart_Desktop_1X.png"
import glassHeart from "../images/benefits/crystal-heart.png"

import crystalHeartMobile from "../images/dosing/crystalHeartMobile.png"
import coupleSmiling from "../images/getting-started/4.0_550x380_Excerise_Desktop_1X.png"
import coupleSmilingMobile from "../images/getting-started/coupleSmilingMobile.png"
import iconCard from    "../images/getting-started/card-icon.png"

import vascepablinklogoLG from "../images/getting-started/vascepa+blinkrx_lg.png"

const footer = `
  <p class="footnote-10 m-t-20 m-b-10 hang">
    <sup>&dagger;</sup>Eligibility criteria, terms and conditions apply. For more information on the savings options available through BlinkRx, as well as the full eligibility criteria, terms and conditions, please click <a href="https://vascepa.com/getting-started/savings-card" target="_blank" style="color: black; font-weight: bold; text-decoration: underline;">here</a>.
  </p>
`

export default () => (
  <Layout footnote={footer}>
    <SEO
      title="Learn How To Get Started"
      keywords="VASCEPA savings, VASCEPA cost, VASCEPA patient savings"
      description="Discover resources and savings to help you start and stay on VASCEPA® (icosapent ethyl). Please see Indication and Important Safety Information."
    />

    <HeroCTA2
      desktopHeroBackground={desktopHero}
      mobileHeroBackground={mobileHero}
      alt="Woman smiling"
      headline={["Get off to a good start with VASCEPA"]}
      content={[
        "Over 15 million prescriptions and counting. Are you next? Make your heart protection plan today. Discover resources & savings to help you start and stay on VASCEPA.",
        <br />,
        <br />,
        "Have questions about why VASCEPA is right for you, or how to pick up your prescription? ",
      ]}
      url1="https://amarincorp.com/docs/VAS-02344VascepaHCCPatientBrochure_CVLaunch_R1.pdf"
      buttonLabel="Download brochure"
      additionalClassName="getting-started-hero"
      external="true"
      imgBackground="get-off"
    />

    <div className="block row">
      {/* <div
        style={{ padding: "0px", minHeight: "300px" }}
        className="col-md-3 block-content whiteborderRight center-img"
      >
			<img src={BlinkRx} alt="BlinkRx logo"></img>
      </div> */}

      <div className="col-md-12 block-content resource-block">
        <img
          class="background-gradient blink-section"
          src={gradientThree}
          alt=""
        ></img>
        <div class="content mobile-p-b-150">
          <img
            className="m-t-40 m-l-40 mobile-m-l-5 mobile-d-100"
            style={{
              zIndex: "1",
              position: "relative",
              display: "inherit",
            }}
            src={vascepablinklogoLG}
            alt="VACEPA nad BlinkRx logo"
          />
          <h3
            className="font-margins"
            style={{
              fontSize: "30px",
              lineHeight: "42px",
              fontWeight: "700",
              zIndex: "1",
              position: "relative",
            }}
          >
            Exclusively with BlinkRx, you have more options to save<sup>&dagger;</sup> on
            VASCEPA
            {/* Blink seeks to find the lowest price for VASCEPA. Ask your
            doctor about <span className="nowrap">e-prescribing</span> to
            BlinkRx U.S. */}
          </h3>
          <p
            className="font-margins"
            style={{
              fontSize: "22px",
              zIndex: "1",
              position: "relative",
            }}
          >
            <strong>How it works</strong> <br />
            <strong>Step 1:</strong> Have your doctor send your prescription to
            BlinkRx U.S. <br />
            <strong>Step 2:</strong> You'll get a text, view your lowest price,
            and check out securely online. <br />
            <strong>Step 3:</strong> Your Rx is delivered free from a local
            pharmacy.
          </p>
          <div class="links">
            <h3>
              <ExternalLink
                link="https://www.blinkhealth.com/vascepa"
                label="Visit the BlinkRx website to learn more >"
                styleName="style-a1"
              />
            </h3>
          </div>
        </div>
      </div>
    </div>

    <RightCta
      alt="A clear VASCEPA® (icosapent ethyl) heart"
      colOne="5"
      colTwo="7"
      whiteborder="whiteborder"
      image={glassHeart}
      mobileImage={crystalHeartMobile}
      background={gradientOne}
      headline={[
        "Questions about taking FDA-approved prescription VASCEPA capsules?",
      ]}
      cta={["How to take VASCEPA ", <span aria-hidden="true">&gt;</span>]}
      url="/getting-started/dosing"
    savingsCardImage="extendImageMobile" 
    mobileImageClass="img-fullheight"
    imgSM="mobile-height-150"
    />

    <LeftCta
      alt="Card icon"
      ctaBackground={ctaBackground}
      colOne="5"
      colTwo="7"
      whiteborder="whiteborder"
      image={iconCard}
      mobileImage={iconCard}
      backgroundColor="#E7E8EA"
      headline="Join the VASCEPA Savings Program for savings on your prescription"
      cta={["Join today ", <span aria-hidden="true">&gt;</span>]}
      url="/getting-started/savings-card"
      headlineColor="#1D355E"
      ctaColor="#1D355E"
      contentSubClass="left"
      ctaSubClass="left"
    />

    {/* <RightCta
      alt="Man and woman staring at each other smiling"
      colOne="5"
      colTwo="7"
      whiteborder="whiteborder"
      image={coupleSmiling}
      mobileImage={coupleSmilingMobile}
      background={gradientOne}
      headline="Stay up-to-date on all things heart healthy and VASCEPA"
      cta={["Sign up ", <span aria-hidden="true">&gt;</span>]}
      url="/getting-started/sign-up"
      mobileImageClass="img-fullwidth"
      imgSM="mobile-height-150"
    /> */}
  </Layout>
)
